<template>
  <section class="w-100 f6 mt0-l mt4 ph4-l ph3">
    <article class="bg-white mh5-l mh0">
      <div class="ph5-l ph4 pv3">
        <div class="tc">
          <div class="ba b--light-gray bw2">
            <h1 class="tc f3 f2-ns ttu tc ttu vidaloka">
              {{ $t("order.error_title") }}
            </h1>
          </div>
        </div>
        <div class="pa5 karla tracked-light lh-copy">
          <div v-if="error" class="dark-red">{{ error }}</div>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "order-error-view",
  computed: {
    ...mapGetters("paypal", {
      error: "error"
    })
  }
};
</script>

<style lang="stylus"></style>
